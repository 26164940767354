<script>
import { h } from 'vue'
import IconComponent from 'src/components/common/icon/icon.vue'
export default {
  name: 'ChecklistComponent',
  props: {
    color: {
      type: String,
      default: undefined
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName() {
      if (this.round) {
        return 'bulletpoint'
      } else {
        return 'checkmark'
      }
    },
    listElements() {
      let elements = []

      if (this.$slots && this.$slots.default) {
        /** When passing a slot such as <li v-for...>, only one slot element is passed
         * with the children property containing the resulting list elements.
         * We want to be able to handle both types of slot contents */

        this.$slots.default().map(slot => {
          if (typeof slot.type === 'symbol' && Array.isArray(slot.children)) {
            elements.push(...slot.children)
          } else {
            elements.push(slot)
          }
        })

        return elements.filter(x => !!x.type)
      }

      return []
    }
  },
  render() {
    return h(
      'ul',
      { class: { checklist: true } },
      this.listElements.map(elem =>
        h(
          'li',
          { class: { 'checklist-item__outer-wrapper': true } },
          elem.type === 'li'
            ? [
                h(IconComponent, {
                  name: elem.props?.icon || this.iconName,
                  up: '3',
                  color: this.color
                }),
                h(
                  'div',
                  {
                    ...elem.props,
                    class: elem.props?.class
                      ? 'checklist-item__inner-wrapper ' + elem.props.class
                      : 'checklist-item__inner-wrapper'
                  },
                  elem.children
                )
              ]
            : [elem]
        )
      )
    )
  }
}
</script>

<style lang="scss" scoped>
.checklist {
  list-style: none;
  padding-left: 0;

  .checklist-item__outer-wrapper {
    display: flex;
    margin-bottom: 6px;

    .icon {
      z-index: 1;

      :deep(svg) {
        fill: var(--root-color);
      }
    }

    .checklist-item__inner-wrapper {
      display: inline-block;
      margin-left: 6px;
      white-space: normal;
    }
  }
}
</style>
